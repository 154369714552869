import React from "react"
import { NavLink } from "react-router-dom";
import classes from "./NavigationHeader.css"
import Logo from "./Logo.png"

const navigationHeader = () => {

  return(
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <NavLink to={"/"}>
          <img className={classes.logo} src={Logo} alt="Bacbee logo"/>
        </NavLink>
      </div>
      <div className={classes.itemContainer}>
        <button className={classes.contact} onClick={() => { window.location = "mailto:info@bacbee.be?subject=Interested in Bacbee" }}>info@bacbee.be</button>
      </div>
    </div>
  );
} 

export default navigationHeader;
