import React from "react"
import NavigationHeader from "./NavigationHeader/NavigationHeader"

import optimization from "./1. building optimization.css"
import opportunities from "./2. opportunities.css"
import benefits from "./3. benefits.css"
import integrated from "./4. integrated.css"
import steps from "./5. steps.css"
import ready from "./6. ready.css"

import Icon1 from "./images/1. building optimization/Icon1.svg"
import Icon2 from "./images/1. building optimization/Icon2.svg"
import Icon3 from "./images/1. building optimization/Icon3.svg"

import Icon4 from "./images/2. opportunities/Icon4.svg"
import Icon5 from "./images/2. opportunities/Icon5.svg"
import Icon6 from "./images/2. opportunities/Icon6.svg"
import Icon7 from "./images/2. opportunities/Icon7.svg"
import Icon8 from "./images/2. opportunities/Icon8.svg"
import Icon9 from "./images/2. opportunities/Icon9.svg"

import Picture1 from "./images/3. benefits/Benefits.png"
import Picture2 from "./images/4. integrated/Integrated.png"
import Picture3 from "./images/5. steps/Ring.png"
import Picture4 from "./images/6. ready/Logo.png"


const Gateway = () => {

  return (
    <>
      <NavigationHeader/>


      <div className={optimization.container}>
        <div className={optimization.contentContainer}>
          <div className={optimization.leftContainer}>
            <div className={optimization.titleBox}>
              <p>Building</p>
              <p>Optimization</p>
              <p>Service</p>
            </div>
          </div>
          <div className={optimization.rightContainer}>
            <div className={optimization.textRow}>
              <div className={optimization.icon}><img src={Icon1} alt="Icon"/></div>
              <p>Collecting data from building infrastructure on a central platform to extract <strong>maximum value</strong></p>
            </div>
            <div className={optimization.textRow}>
              <div className={optimization.icon}><img src={Icon2} alt="Icon"/></div>
              <p><strong>Integrated approach</strong> with experts for integration, analysis and optimization. Including application of algorithms and AI for anomaly detection on energy consumption and installations</p>
            </div>
            <div className={optimization.textRow}>
              <div className={optimization.icon}><img src={Icon3} alt="Icon"/></div>
              <p>Shift from dashboards to alarms, reporting and <strong>actions</strong></p>
            </div>
          </div>
        </div>
        <div className={optimization.bottomBorder}></div>
      </div>

      <div className={opportunities.container}>

        <p className={opportunities.subtitle}>We combine data from energy monitoring, building installations and additional sensors together with the knowledge of experts in various fields to enable a range of value-added services, custom tailored to the needs of the client.</p>

        <div className={opportunities.contentContainer}>

          <div className={opportunities.hexagon}>
            <p>Opportunities</p>
          </div>

          <div className={opportunities.item}>
            <img src={Icon4} alt="Icon"/>
            <p>Anomaly detection</p>
          </div>
          <div className={opportunities.item}>
            <img src={Icon5} alt="Icon"/>
            <p>PV monitoring</p>
          </div>
          <div className={opportunities.item}>
            <img src={Icon6} alt="Icon"/>
            <p>Water leak detection</p>
          </div>
          <div className={opportunities.item}>
            <img src={Icon7} alt="Icon"/>
            <p>HVAC optimization</p>
          </div>
          <div className={opportunities.item}>
            <img src={Icon8} alt="Icon"/>
            <p>Comfort monitoring</p>
          </div>
          <div className={opportunities.item}>
            <img src={Icon9} alt="Icon"/>
            <p>Energy monitoring & analysis</p>
          </div>
        </div>
        <div className={optimization.bottomBorder}></div>
      </div>

      <div className={benefits.container}>
        <h1 className={benefits.title}>Why <strong>Bacbee</strong>?</h1>
        <div className={benefits.contentContainer}>
          <div className={benefits.leftContainer}>
            <img src={Picture1} alt="Electricity consumption graph"/>
          </div>
          <div className={benefits.rightContainer}>
            <div className={benefits.textRow}>
              <p>Collection of data on central platform for <strong>easy reporting and analysis</strong></p>
            </div>
            <div className={benefits.textRow}>
              <p><strong>Lowering energy costs</strong> through optimizations and investments</p>
            </div>
            <div className={benefits.textRow}>
              <p>Monitoring of energy costs and installations is provided by software to maximally <strong>assist the building manager</strong></p>
            </div>
            <div className={benefits.textRow}>
              <p><strong>Detect invisible defects</strong> through AI and automatic alarms</p>
            </div>
          </div>
        </div>
        <div className={benefits.bottomBorder}></div>
      </div>

      <div className={integrated.container}>
        <div className={integrated.textContainer}>
          <h1><strong>Integrated</strong> solutions</h1>
          <p>From low level protocols to advanced API's, Bacbee integrates a complete ecosystem into one central service. Our gateways gather data from meters and equipment on site while our cloud services connect to digital sources. Collected data is then processed by advanced algorithms to identify opportunities for improvements and generate alerts when inefficiencies occur.</p>
        </div>
        <img src={Picture2} alt="Integrated service logo's"/>
        <div className={optimization.bottomBorder}></div>
      </div>

      <div className={steps.container}>
        <div className={steps.contentContainer}>
          <div className={steps.centerTitle}>
            Your<br/>
            <strong>step-by-step plan</strong><br/>
            with Bacbee
          </div>
          <img src={Picture3} className={steps.ringImage} alt="Step by step plan"/>
          <div className={steps.explainContainer}>
            <h1>Analysis</h1>
            <p>Inventory of building infrastructure and specific needs of the client</p>
          </div>
          <div className={steps.explainContainer}>
            <h1>Tailored offer</h1>
            <p>Crafting a personalized offer for the client</p>
          </div>
          <div className={steps.explainContainer}>
            <h1>Implementation</h1>
            <p>Installation of hardware<br/>Building the models and algorithms</p>
          </div>
          <div className={steps.explainContainer}>
            <h1>Optimization</h1>
            <p>Reporting to the client<br/>Proposal for action</p>
          </div>
          <div className={steps.explainContainer}>
            <h1>Surveillance</h1>
            <p>Ensuring optimal performance</p>
          </div>
        </div>
        <div className={optimization.bottomBorder}></div>
      </div>

      <div className={ready.container}>
        <h1 className={ready.title}>Ready to <strong>transform your building</strong>?</h1>
        <img src={Picture4} alt="Bacbee logo"/>
      </div>
    </>
  )
}

export default Gateway;
