import React, { Component } from 'react';
import Layout from "./containers/Layout/Layout";
import { Route, Switch } from "react-router-dom";

// General
import Overview    from "./components/overview/0. Overview"

class App extends Component {

  render() {
    return (
      <div className="App">
        <div id="alert-view-container"></div>
        <Layout>
          <Switch>
            <Route path="/" component={Overview}></Route>
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default App;
