import React from "react";
import classes from "./Layout.css";


const layout = (props) => {

  return (
    <>
      <main className={classes.content + " mainContainer"}>
        {props.children}
      </main>
    </>
  );
}

export default layout;
